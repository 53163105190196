import React from 'react'
import { Box, Flex } from 'src/components/Box'
import { Body, Title2 } from 'src/components/text'
import { ActivityModel } from 'src/contentful/models'
import Disclosure from 'src/components/Disclosure'
import { strings } from 'src/strings'
import { renderRichTextDocument } from './index'

export const Activity: React.FunctionComponent<ActivityModel> = ({
  title,
  introduction,
  activity,
}) => {
  return (
    <Flex flexDirection="column" mt="5" mb="8">
      <Title2 mb="4" as="div">
        {/* as="div" to avoid being styled by <DeduplicateExaggeratedMargins /> */}
        {title}
      </Title2>
      <Body mb="5">{introduction}</Body>
      <Disclosure
        label={strings.projectOverview.disclosureButton}
        title={title}
      >
        <Box p="4" bg="lightestGrey">
          {renderRichTextDocument(activity)}
        </Box>
      </Disclosure>
    </Flex>
  )
}
