import React from 'react'
import { SmallBody } from 'src/components/text'
import { Link } from 'src/components/Link'
import { projectPath } from 'src/lib/paths'
import { BackArrowIcon } from 'src/components/icons'
import strings from 'src/strings'

interface Props {
  projectId: number
}

export const BackToProjectOverview: React.FunctionComponent<Props> = ({
  projectId,
}) => (
  <SmallBody>
    <BackArrowIcon height={9} />{' '}
    <Link to={projectPath(projectId)}>
      {strings.projectOverview.backToProjectHome}
    </Link>
  </SmallBody>
)
